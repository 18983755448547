import React from 'react'
import { PropsWithChildren } from 'react'
import {
  ActionButton,
  Dialog,
  DialogFooter,
  IButtonStyles,
  IDialogContentProps,
  PrimaryButton,
} from '@fluentui/react'

type Props = PropsWithChildren & {
  onDismiss: () => void
  onConfirm?: (...args: any) => void
  onDismissed?: () => void
  title?: string
  hidden?: boolean
  confirm?: string
  cancel?: string | boolean
  disableBtn?: boolean
  cancelBtn?: string | boolean
  confirmBtnTitle?: string
  btnStyles?: Partial<IButtonStyles>
  dialogContentProps?: IDialogContentProps
}

const ConfirmDialog: React.FC<Props> = (props: Props) => {
  const cancelBtn = props.cancelBtn === false ? null : props.cancelBtn ?? 'Cancel'

  return (
    <Dialog
      hidden={props.hidden}
      onDismiss={props.onDismiss}
      dialogContentProps={{ title: props.title, ...props.dialogContentProps }}
      modalProps={{
        isBlocking: true,
        onDismissed: props.onDismissed,
      }}
    >
      {props.children}
      <DialogFooter>
        {props.confirm && (
          <PrimaryButton
            onClick={props.onConfirm}
            disabled={props.disableBtn}
            title={props.confirmBtnTitle}
            styles={props.btnStyles}
          >
            {props.confirm}
          </PrimaryButton>
        )}
        {cancelBtn && <ActionButton onClick={props.onDismiss}>{cancelBtn}</ActionButton>}
      </DialogFooter>
    </Dialog>
  )
}

export default ConfirmDialog
