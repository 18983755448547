import { CSSProperties, PropsWithChildren } from 'react'

import Box from '@baseComponents/Box'
import BoldText from '@baseComponents/BoldText'

type Props = PropsWithChildren & {
  title?: string
  topRight?: JSX.Element
  bottomRight?: JSX.Element
  style?: CSSProperties
  titleStyle?: CSSProperties
}

export default function ContentCard({
  title,
  children,
  topRight,
  bottomRight,
  style,
  titleStyle,
}: Props) {
  return (
    <Box style={style}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
        <BoldText style={titleStyle}>{title}</BoldText>
        {topRight}
      </div>
      <div style={{ padding: '0.7em 0' }}>{children}</div>
      <div style={{ textAlign: 'right' }}>{bottomRight}</div>
    </Box>
  )
}
