import {
  definitionFontColor,
  definitionHighlightColor,
  selectTermInDocument,
} from '@modules/DocumentDefinitions'
import BoldText from '@baseComponents/BoldText'
import Box from '@baseComponents/Box'
import { CSSProperties } from 'react'
import UnstyledActionButton from '@baseComponents/UnstyledActionButton'
import ContentCard from '@components/ContentCard'

interface Definition {
  text: string
  offset: number
  paragraph_index?: number
  occurrence_index?: number
}

interface Location {
  offset: number
  length: number
}

interface Term extends Location {
  text: string
  paragraph_index?: number
  subdivision_index?: number
  occurrence_index?: number
}

interface Ref extends Location {
  definitionId: number
}

interface Token extends Ref {
  isTerm: boolean
}

interface DefinitionProps {
  definition: Definition
  term: Term
  references: Ref[]
  onClose: () => void
  onClick: (definitionId: number) => void
  onView: () => void
  style: CSSProperties
}

const refStyles = {
  color: definitionFontColor,
  backgroundColor: definitionHighlightColor,
  fontWeight: 'bold',
  cursor: 'pointer',
}

function scaleReferenceOffset(definitionOffset: number, references: Token[]) {
  return references.map(({ offset, length, ...rest }) => {
    const newStart = offset - definitionOffset
    return { start: newStart, end: newStart + length, ...rest }
  })
}

export function tokenizeDefinition(definition: Definition, term: Term, references: Ref[]) {
  const { text, offset } = definition
  const termAndRefsMap = new Map(references.map(obj => [obj.offset, { ...obj, isTerm: false }]))
  termAndRefsMap.set(term.offset, { ...term, isTerm: true, definitionId: -1 })
  const termAndRefsList = Array.from(termAndRefsMap.values())
  termAndRefsList.sort((a, b) => a.offset - b.offset)
  let currentStart = 0
  const tokens = scaleReferenceOffset(offset, termAndRefsList).flatMap(
    ({ start, end, definitionId, isTerm }) => {
      const tokens = [
        { text: text.substring(currentStart, start) },
        { text: text.substring(start, end), isReference: !isTerm, definitionId, isTerm },
      ]
      currentStart = end
      return tokens
    },
  )
  tokens.push({ text: text.substring(currentStart) })
  return tokens
}

function renderViewInDocument(term: Term, onView: () => void) {
  if (typeof term.paragraph_index === 'undefined' || typeof term.occurrence_index === 'undefined') {
    return <div style={{ paddingBottom: '0.7em' }}></div>
  }

  return (
    <UnstyledActionButton
      iconProps={{ iconName: 'DocumentSearch' }}
      onClick={() => {
        selectTermInDocument(term)
        onView()
      }}
    >
      View in Document
    </UnstyledActionButton>
  )
}

export default function DefinitionCard(props: DefinitionProps) {
  const { definition, term, references, onClick, onClose, onView, style } = props
  const rendered = tokenizeDefinition(definition, term, references).map((token, index) => {
    const { definitionId, isTerm, isReference, text } = token
    const textComp = isTerm ? <BoldText>{text}</BoldText> : text
    function handleClick() {
      definitionId !== undefined && !isTerm && onClick(definitionId)
    }

    return (
      <span
        key={index}
        role="button"
        tabIndex={index}
        style={isReference ? refStyles : {}}
        onClick={() => handleClick()}
        onKeyDown={e => e.code === 'Enter' && handleClick()}
      >
        {textComp}
      </span>
    )
  })

  return (
    <ContentCard
      title={term.text}
      topRight={
        <UnstyledActionButton
          iconProps={{ iconName: 'Cancel' }}
          onClick={onClose}
          ariaLabel="Close Button"
        />
      }
      bottomRight={renderViewInDocument(term, onView)}
      style={style}
    >
      {rendered}
    </ContentCard>
  )
}
