import { CSSProperties, useState } from 'react'
import { t } from 'i18next'
import {
  Text,
  Callout,
  DirectionalHint,
  Icon,
  Stack,
  List,
  IStyleFunctionOrObject,
  ICalloutContentStyleProps,
  ICalloutContentStyles,
} from '@fluentui/react'
import { useId } from '@fluentui/react-hooks'

import { AccessControls, mapAccessObjectToUiAccess } from '@blaw/contracts-api-schema'
import IconBadge from '@components/IconBadge'
import NotificationBadge from '@components/NotificationBadge'
import { getFullName, UserInfo } from '@modules/GetUsers'
import { getSession } from '@modules/SessionInfoStorage'
import { accessDisplayData } from '@modules/accessDisplayData'

type Props = {
  access: AccessControls
  users: UserInfo[]
  enableV2?: boolean
  styles?: {
    root?: CSSProperties
    icon?: CSSProperties
    callout?: IStyleFunctionOrObject<ICalloutContentStyleProps, ICalloutContentStyles>
  }
}

export default function ResourceAccessLabel(props: Props) {
  const { access, users, enableV2 = false, styles } = props

  const displayData = accessDisplayData(access, enableV2)

  const [calloutHidden, setCalloutHidden] = useState(true)
  const buttonId = useId('callout-button')

  const uiAccess = mapAccessObjectToUiAccess(access)
  const userInfo = getSession()
  const individuals = Object.keys(uiAccess.individualAccess)
    .filter(key => key !== userInfo.uuid)
    .map(uuid => users.find(user => user.bbUuid.toString() === uuid))
  const hasIndividuals = enableV2 && individuals.length > 0

  return (
    <>
      <div
        style={{
          flexGrow: 2,
          display: 'flex',
          alignItems: 'center',
          cursor: hasIndividuals ? 'pointer' : 'default',
          ...styles?.root,
        }}
        aria-label={t('label.resource-access')}
        role="button"
        tabIndex={0}
        onClick={() => (hasIndividuals ? setCalloutHidden(!calloutHidden) : null)}
        onKeyDown={e => {
          if (e.key === 'Enter' && hasIndividuals) {
            setCalloutHidden(!calloutHidden)
          }
        }}
      >
        <IconBadge
          iconName={displayData.iconName}
          iconColor={displayData.iconColor}
          text={displayData.label}
          badgeColor="white"
          style={styles?.icon}
        />
        {hasIndividuals && (
          <>
            <NotificationBadge style={{ marginRight: '0.5em' }}>
              {individuals.length}
            </NotificationBadge>
            <Icon id={buttonId} iconName={calloutHidden ? 'ChevronDown' : 'ChevronUp'} />
          </>
        )}
      </div>
      {!calloutHidden && (
        <Callout
          target={`#${buttonId}`}
          role="dialog"
          gapSpace={3}
          beakWidth={8}
          styles={styles?.callout}
          directionalHint={DirectionalHint.bottomRightEdge}
        >
          <Stack
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              padding: '0.5em',
            }}
          >
            <List
              items={individuals}
              onRenderCell={item => item && <Text>{getFullName(item)}</Text>}
            />
          </Stack>
        </Callout>
      )}
    </>
  )
}
