import { useRef, useState } from 'react'
import {
  ActionButton,
  IStyle,
  ITextField,
  ITextFieldProps,
  Stack,
  TextField,
} from '@fluentui/react'

import SmallButton from '@components/SmallButton'
import BoldText from '@baseComponents/BoldText'
import useLoadingFadeStyle from '@hooks/useLoadingFadeStyle'

type Props = ITextFieldProps & {
  labelPrefix: string
  values: string[]
  onValueChange: (values: string[], index: number) => void
  onRemove?: (HKindex: number) => void
  addBtnLabel?: string
  maxFields?: number
  loading?: boolean
  btnStyles?: IStyle
}
const empty = ['']

export default function AddAnotherTextField(props: Props) {
  const [inputs, setInputs] = useState(props.values.length ? props.values : empty)
  const loadingFade = useLoadingFadeStyle(props.loading || props.disabled)
  const inputsRef = useRef<(ITextField | null)[]>([])

  return (
    <>
      {inputs.map((value, i) => {
        const label = `${props.labelPrefix} ${i + 1}`
        return (
          <div key={i} style={loadingFade}>
            <Stack
              horizontal
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '0.3em',
              }}
            >
              <BoldText>{label}</BoldText>
              <SmallButton
                iconName="Cancel"
                variant="destructive"
                title={`Remove ${label}`}
                onClick={() => {
                  removeValue(i)
                  props.onRemove?.(i)
                  inputsRef.current[i == 0 ? 0 : i - 1]?.focus()
                }}
              />
            </Stack>

            <TextField
              componentRef={input => (inputsRef.current[i] = input)}
              value={value}
              disabled={props.loading}
              autoFocus={i === 0 || i === inputs.length - 1}
              placeholder={props.placeholder}
              onChange={(_, value) => {
                const newInputs = structuredClone(inputs)
                newInputs.splice(i, 1, value ?? '')
                setInputs(newInputs)
                props.onValueChange(newInputs, i)
              }}
              {...props}
            />
          </div>
        )
      })}

      <ActionButton
        // @ts-ignore ts complains btnStyles is not spreadable but it is
        styles={{ root: { paddingLeft: 0, ...loadingFade, ...props.btnStyles } }}
        iconProps={{ iconName: 'Add' }}
        disabled={props.maxFields ? inputs.length >= props.maxFields : false}
        onClick={() => setInputs([...inputs, ''])}
      >
        {props.addBtnLabel}
      </ActionButton>
    </>
  )

  function removeValue(i: number) {
    if (i == 0 && inputs.length === 1) {
      setInputs(empty)
      props.onValueChange(empty, i)
      return
    }
    const newInputs = structuredClone(inputs)
    newInputs.splice(i, 1)
    setInputs(newInputs)
    props.onValueChange(newInputs, i)
  }
}
