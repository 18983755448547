import { CSSProperties, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  List,
  NeutralColors,
  IContextualMenuItem,
  FontWeights,
  mergeStyleSets,
  ProgressIndicator,
} from '@fluentui/react'
import { t } from 'i18next'

import { StyledDivider } from '@baseComponents/StyledDivider'
import { PlaybookResource } from '@blaw/contracts-api-schema'
import ActionsMenu from '@components/ActionsMenu'
import BoldField from '@components/BoldField'
import LoadingShimmer from '@components/LoadingShimmer'
import QuickMessage from '@components/QuickMessage'
import { friendlyDateTime } from '@modules/utils'
import { DISPLAYED_RESOURCETYPES } from '@src/data/displayedResourceTypes'
import { PlaybooksContext } from '@contexts/PlaybooksContext'
import DeletePlaybookForm from '@components/DeletePlaybookForm'
import LinkButton from '@components/LinkButton'

type Props = {
  resources: PlaybookResource[]
  style?: CSSProperties
  onRenderResource?: (item?: PlaybookResource) => JSX.Element | null
}

const playbookActions: IContextualMenuItem[] = [
  {
    key: 'comply',
    text: t('button.Check Compliance'),
    iconProps: { iconName: 'WaitlistConfirm' },
  },
  // TODO: POST - MVP
  // {
  //   key: 'download',
  //   text: 'Download',
  //   iconProps: { iconName: 'Download' },
  // },
  {
    key: 'edit',
    text: 'Edit',
    iconProps: { iconName: 'PageEdit' },
  },
  {
    key: 'delete',
    text: 'Delete',
    iconProps: { iconName: 'Delete' },
  },
]

export default function PlaybooksList({ resources, style }: Props) {
  const { error, loading } = useContext(PlaybooksContext)

  if (loading) return <Loading />
  if (error) return <QuickMessage msg={error} type="error" />

  return (
    <div style={{ marginBottom: '2em' }}>
      <List
        items={resources}
        onShouldVirtualize={() => false}
        onRenderCell={item => <PlaybookItem playbook={item} error={error} style={style} />}
      />
    </div>
  )
}

type PlaybookItemProps = {
  playbook?: PlaybookResource
  error?: string
  style?: CSSProperties
}

function PlaybookItem({ playbook, error, style }: PlaybookItemProps) {
  const navigate = useNavigate()
  const [deleteModalHidden, setDeleteModalHidden] = useState(true)

  if (!playbook) return null
  if (!DISPLAYED_RESOURCETYPES.includes(playbook.resourceType)) return null

  const newCompliancePath = `/playbooks/${playbook.id}/compliance/new?title=${playbook.name}&type=${playbook.metadata.userMetadata.contract_type}&role=${playbook.metadata.userMetadata.playbook_party_role}`
  const editPlaybookPath = `/playbooks/${playbook.id}/edit?name=${playbook.name}`

  return (
    <>
      <div
        style={{
          padding: '0.5em 0 1em 0',
          ...style,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <LinkButton
            href={`#${editPlaybookPath}`}
            iconName="WaitlistConfirm"
            buttonStyles={{
              root: { fontWeight: FontWeights.semibold },
            }}
          >
            {playbook.name}
          </LinkButton>

          <div className={additionalStyles.hoverStyle}>
            <ActionsMenu
              items={playbookActions}
              onItemClick={onItemClick}
              iconStyles={{
                border: `1px solid ${NeutralColors.gray60}`,
                borderRadius: '0.2em',
                padding: '0.1em 0.2em',
              }}
            />
          </div>
        </div>

        <BoldField
          label={`${t('label.ContractMetadata.last-modified')}: `}
          value={playbook.dateModified ? `${friendlyDateTime(playbook.dateModified)}` : ''}
        />
        <DeletePlaybookForm
          id={playbook.id}
          name={playbook.name}
          hidden={deleteModalHidden}
          setDeleteModalHidden={setDeleteModalHidden}
          error={error}
        />
      </div>
      <StyledDivider />
    </>
  )

  function onItemClick(action: IContextualMenuItem) {
    switch (action.key) {
      case 'comply':
        navigate(newCompliancePath)
        break
      case 'download':
        console.error('Playbook download click unimplemented')
        break
      case 'edit':
        navigate(editPlaybookPath)
        break
      case 'delete':
        setDeleteModalHidden(false)
        break
      default:
        throw Error(`Invalid playbook action: ${action.key}`)
    }
  }
}

function Loading() {
  return (
    <>
      <ProgressIndicator label="Loading your Playbooks..." />
      <LoadingShimmer />
    </>
  )
}

const additionalStyles = mergeStyleSets({
  hoverStyle: {
    selectors: {
      i: {
        ':hover': {
          background: NeutralColors.gray30,
        },
      },
    },
  },
})
